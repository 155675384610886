@import "styles/colours";

@media only screen and (min-width: 700px) {
  .menu-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: visibility 0s, opacity 0.5s linear;
  }

  .menu-div-my-name {
    width: 20%;
    > img {
      height: 45%;
    }
    > a {
      transform: translateX(35%) translateY(-50%);
    }
    a:hover {
      color: $color-two;
      transition: color .5s;
    }
  }

  .menu-div-topics {
    position: relative;
    width: 60%;
    > ul {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      flex-direction: row;
      justify-content: space-evenly;
      > li {
        > a {
          > p {
            font-size: 140%;
          }
          p:hover {
            color: $color-two;
            transition: color .5s ease;
          }
        }
      }
    }
  }
}
