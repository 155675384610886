@import "styles/colours";

@media only screen and (min-width: 700px) {
  .intro_div {
    height: 70%;
    flex-direction: row;
  }

  .flex-item-left {
    flex: 50%;
    padding-top: 20vh;
    p {
      padding-left: 10%;
      text-align: left;
    }
    h1 {
      font-weight: lighter;
      padding-left: 10%;
      text-align: left;
    }
    .p1 {
      margin: 5% 0 5% 0;
      font-size: calc(80px + 50%);
    }
    .p2 {
      margin: 3% 0 3% 0;
      font-size: calc(40px + 50%);
    }
  }

  .flex-item-right {
    img {
      height: 85%;
      max-height: 60vh;
    }
  }

  .social-media-container {
    height: auto;
  }

  .social-media {
    margin: 0 10% 0 60%;
    .social-media-text-container {
      .social-media-text {
        font-size: 180%;
      }
    }
  }

  .icon-borders {
    width: 80px;
    height: 80px;
  }

}
