@import "styles/colours";


@media only screen and (max-width: 700px) {
  .menu-div {
    transition: visibility 0s, opacity 0.5s linear, height 0.2s linear;
  }

  .menu-div-opened {
    height: 40vh;
    transition: visibility 0s, height 0.2s linear, opacity 0.5s linear;
  }
  .menu-div-closed {
    height: 5vh;
    transition: visibility 0s, height 0.2s linear, opacity 0.5s linear;
  }

  .menu-div-my-name {
    width: 80%;
    > img {
      height: 50%;
    }
    > a {
      transform: translateX(35%) translateY(-50%);
    }
  }

  .menu-button {
    border: 0; 
    padding: 0 2% 0 0;
    background-color: transparent !important;
    > img {
      height: 70%;
    }
    cursor: pointer;
  }

  .menu-div-topics {
    height: 95%;
    > ul {
      height: 100%;
      flex-direction: column;
      > li {
        height: 15%;
        > a {
          > p {
            text-align: center;
            font-size: 130%;
          }
        }
      }
    }
  }
}
